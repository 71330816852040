import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'usehooks-ts';

import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';

import { CONDUCTRICS_GOALS } from '../../helpers/constants';
import { ChildComponentThemeProvider } from '../../helpers/providers/theme/theme-provider';
import { generateUrl } from '../../helpers/utils/client';
import { useTheme } from '../../hooks/use-theme';
import { BrandLink } from '../brand-buttons/brand-buttons';

import type { HotelVideo, Maybe } from '@dx-ui/gql-types';

export type StayTourVideo = {
  hotelName?: Maybe<string>;
  video: Omit<HotelVideo, 'title' | 'metadata' | 'secondaryVideos'>;
};

type StayTourButtonsProps = {
  relativeUrl: string;
  roomsRelativeUrl: string;
};

const StayTourButtons = ({
  relativeUrl,
  roomsRelativeUrl,
}: StayTourButtonsProps): React.JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-col gap-2 lg:flex-row">
      <BrandLink
        anchorClassName="w-full text-center text-nowrap md:w-auto"
        label={t('stay-tour-video.cta')}
        onClick={async () => await sendRewardAsync(CONDUCTRICS_GOALS.VIDEO_CLICK_CTA)}
        url={relativeUrl}
      />
      <BrandLink
        anchorClassName="w-full text-center text-nowrap md:w-auto"
        label={t('stay-tour-video.secondary-cta')}
        onClick={async () => await sendRewardAsync(CONDUCTRICS_GOALS.STAY_TOUR_VIDEO_SECONDARY_CTA)}
        url={roomsRelativeUrl}
      />
    </div>
  );
};

const StayTourVideoContent = ({ hotelName, video }: StayTourVideo) => {
  const { t } = useTranslation('common');
  const { relativeUrl } = generateUrl(useRouter(), 'gallery');
  const { relativeUrl: roomsRelativeUrl } = generateUrl(useRouter(), 'rooms');
  const { theme } = useTheme();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isVideoOnRightSide = theme.additionalThemeOptions?.layout === 'right';

  const StayTourVideoDescription: React.FC<{ hotelName: Maybe<string> | undefined }> = ({
    hotelName,
  }) => {
    return hotelName ? (
      <p className="py-7 text-center md:pt-0 md:text-start">
        {t('stay-tour-video.description', { hotelName })}
      </p>
    ) : null;
  };

  return (
    <div
      data-testid="layout"
      className={cx(
        `max-h-3/4 flex flex-col px-4 py-7 md:w-full md:items-center md:justify-center md:gap-20 md:p-0 ${theme?.base}`,
        {
          'md:flex-row': isVideoOnRightSide,
          'md:flex-row-reverse': !isVideoOnRightSide,
        }
      )}
    >
      {isMobile ? (
        <>
          <h2
            className={cx('heading-2xl pb-7 text-center', {
              'accent-heading': theme?.accent?.heading,
            })}
          >
            {t('stay-tour-video.headline')}
          </h2>
          <YouTubeVideo
            isAutoLoop
            isAutoPlay={false}
            id={video?.id ?? ''}
            isVertical={true}
            name={video?.name ?? ''}
            source={video?.source ?? ''}
            title={video?.name ?? ''}
            url={video.url}
          />
          <StayTourVideoDescription hotelName={hotelName} />
          <StayTourButtons relativeUrl={relativeUrl} roomsRelativeUrl={roomsRelativeUrl} />
        </>
      ) : (
        <>
          <div className="flex flex-col md:w-1/3 md:items-start">
            <h2
              className={cx('heading-2xl lg:heading-3xl hidden pb-7 md:block', {
                'accent-heading': theme?.accent?.heading,
              })}
            >
              {t('stay-tour-video.headline')}
            </h2>
            <StayTourVideoDescription hotelName={hotelName} />
            <StayTourButtons relativeUrl={relativeUrl} roomsRelativeUrl={roomsRelativeUrl} />
          </div>
          <div>
            <YouTubeVideo
              isAutoLoop
              isAutoPlay={false}
              id={video?.id ?? ''}
              isVertical={true}
              name={video?.name ?? ''}
              source={video?.source ?? ''}
              title={video?.name ?? ''}
              url={video.url}
            />
          </div>
        </>
      )}
    </div>
  );
};

const StayTourVideo = ({ hotelName, video }: StayTourVideo) => {
  const { theme } = useTheme();

  return (
    <div className="h-full sm:px-4 md:p-0 md:pb-5 md:pt-36">
      <div className="md:h-[480px]">
        <ChildComponentThemeProvider theme={theme.childTheme!}>
          <StayTourVideoContent hotelName={hotelName} video={video} />
        </ChildComponentThemeProvider>
      </div>
    </div>
  );
};

export default StayTourVideo;
